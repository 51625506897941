// component
import SvgColor from '../../../components/svg-color';
import StoreIcon from '@mui/icons-material/Store';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import GroupIcon from '@mui/icons-material/Group';
import QrCode2Icon from '@mui/icons-material/QrCode2';
import TextSnippetIcon from '@mui/icons-material/TextSnippet';
// ----------------------------------------------------------------------

const icon = (name) => <SvgColor src={`/assets/icons/navbar/${name}.svg`} sx={{ width: 1, height: 1 }} />;

const navConfig = [
  {
    title: 'Livraisons Passée',
    path: '/dashboard/livraison',
    icon: <LocalShippingIcon/>,
  },
  {
    title: 'Fournisseurs',
    path: '/dashboard/supplier',
    icon: <StoreIcon/>,
  },
  {
    title: 'Gammes',
    path: '/dashboard/collections',
    icon: <QrCode2Icon/>,
  },
  {
    title: 'Produits',
    path: '/dashboard/products',
    icon: icon('ic_cart'),
  },
  {
    title: 'Clients',
    path: '/dashboard/clients',
    icon: <GroupIcon/>,
  },
  {
    title: 'Documents',
    path: '/dashboard/documents',
    icon: <TextSnippetIcon/>,
  },
];

export default navConfig;
