import React,{ useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography,  MenuItem, Snackbar, TextField, Button, IconButton, Popover, Modal, Container, Stack, InputAdornment} from '@mui/material';
// mocks_
import { supabaseClient } from 'src/supabase';
import { useNavigate } from 'react-router-dom';
import Iconify from 'src/components/iconify/Iconify';
import Scrollbar from 'src/components/scrollbar';
import MuiAlert from '@mui/material/Alert';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
  {
    label: 'Home',
    icon: 'eva:home-fill',
  },
  {
    label: 'Profile',
    icon: 'eva:person-fill',
  },
  {
    label: 'Settings',
    icon: 'eva:settings-2-fill',
  },
];

// ----------------------------------------------------------------------
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export default function AccountPopover() {
  const navigate = useNavigate();
  const [open, setOpen] = useState(null);
  const [password,setPassword] = useState('');
  const [passwordConf,setPasswordConf] = useState('');
  const [openModal, setOpenModal] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const handleCloseModal = () => setOpenModal(false);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const logout = () => {
    supabaseClient.auth.signOut()
    navigate('/login',{replace:true})
  }
  const [openBar, setOpenBar] = useState(false);
  const [alertMessage,setAlertMessage] = useState('');

  const handleOpenBar = (message)=>{
    setAlertMessage(message)
    setOpenBar(true)
  }

  const handleCloseBar = (event, reason) => {
    setOpenBar(false);
  };

  const handleEditPassword = async () => {

    const {data:currentUser,error} = await supabaseClient.auth.getSession()
    if (error) console.log('Error', error)
    let newUser = {
      id : currentUser.session.user.id,
      password:password,
    }
    const {data:user,error:err} = await supabaseClient.functions.invoke("update-password",{headers:{Authorization:`Bearer ${currentUser.session.access_token}`},body:newUser})
    console.log(err)
    console.log(user)
    
    handleCloseModal()
    handleOpenBar(`Le mots de passe a été changer.`)
    setPassword('')
    setPasswordConf('')
    
};

  return (
    <>
        <Snackbar open={openBar} anchorOrigin={{ vertical:"top", horizontal:'center' }} autoHideDuration={6000} onClose={handleCloseBar}>
            <Alert onClose={handleCloseModal} severity="success" sx={{ width: '100%' }}>
                {alertMessage}
            </Alert>
        </Snackbar>

        <Modal
            open={openModal}
            onClose={handleCloseModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{alignItems:"center",justifyContent:'center'}}
        >
            <Container 
                sx={{width:'90%',
                    hight:'80%',
                    alignSelf:'center',
                    borderColor:'#103996',
                    borderRadius:"10px",
                    backgroundColor:'#fff',
                    padding:5,
                    justifySelf:'center',
                    marginTop:"5%"}}
            >
                <Scrollbar >
                <Stack direction="column" alignItems="center" justifyContent="space-evenly" alignContent='center' m={1} spacing={2}>
                    <Typography variant="h6" noWrap alignContent='center'>
                        Mettre à jour Votre mot de passe !
                    </Typography>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <TextField
                        sx={{width:'50%',minWidth:250}}
                        onChange={(e)=>setPassword(e.target.value)}
                        name="password"
                        label="Mot de Passe"
                        type={showPassword ? 'text' : 'password'}
                        helperText={
                            <Typography variant="h8" noWrap alignContent='center' color="text.secondary">
                                <strong> 6 Characteres Minimum </strong>
                            </Typography>
                        }
                        InputProps={{
                            minlenght:'5',
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                    />
                    <TextField
                        sx={{width:'50%',minWidth:250}}
                        onChange={(e)=>setPasswordConf(e.target.value)}
                        name="password"
                        label="Confirmer Le Mot de Passe"
                        type={showPassword ? 'text' : 'password'}
                        helperText={
                            <Typography variant="h8" noWrap alignContent='center' color="text.secondary">
                                <strong> 6 Characteres Minimum </strong>
                            </Typography>
                        }
                        InputProps={{
                            endAdornment: (
                            <InputAdornment position="end">
                                <IconButton onClick={() => setShowPassword(!showPassword)} edge="end">
                                <Iconify icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'} />
                                </IconButton>
                            </InputAdornment>
                            ),
                        }}
                    />
                    <Typography variant="h8" noWrap alignContent='center' color="text.secondary">
                    <strong> Veuillez noter Votre noveau mot de passe !</strong>
                    </Typography>
                    <Button 
                        variant="contained" 
                        onClick={handleEditPassword} 
                        mt={2} 
                        disabled={(password.length < 6) || (password !== passwordConf)}
                    >
                    Mettre à jour votre mot de passe
                    </Button>
                </Stack>
                </Scrollbar>
            </Container>
        </Modal>
      <IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              borderColor:'gray',
              borderWidth:'3px',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Iconify icon={'eva:person-outline'} />
      </IconButton>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            BC Solution MA
          </Typography>
          
        </Box>
        

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={logout} sx={{ m: 1 }}>
          Se déconnecter
        </MenuItem>
        <MenuItem onClick={()=>setOpenModal(true)} sx={{ m: 1 }}>
          Mots de Passe
        </MenuItem>
      </Popover>
    </>
  );
}
