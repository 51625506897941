import React,{ useState,useEffect } from "react";
import { Helmet } from 'react-helmet-async';
// @mui
import {
    Popover,
    Card,
    Table,
    TableRow,
    TableHead,
    MenuItem,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Button,
    Container, 
    Stack, 
    Typography, 
    Modal, 
    TextField, 
    InputAdornment, 
    InputLabel ,
    FormControl ,
    Select ,
    IconButton, 
    Divider, 
    Snackbar,
    Toolbar,
    OutlinedInput,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { supabaseClient } from 'src/supabase';
import { useNavigate} from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {fDate} from '../utils/formatTime';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

//----------------------------------------------------------------------

pdfMake.vfs = pdfFonts.pdfMake.vfs;


//----------------------------------------------------------------------
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
  }));
  
const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: "100%",
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));
  
// ----------------------------------------------------------------------


export default function Livraison (){
    const navigate = useNavigate();
    const [open, setOpen] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [name,setName] = useState('');
    const [companies,setCompanies] = useState([]);
    const [fournisseurs,setFournisseurs] = useState([]);

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const [openBar, setOpenBar] = useState(false);
    const [alertMessage,setAlertMessage] = useState('');

    const handleOpenBar = (message)=>{
        setAlertMessage(message)
        setOpenBar(true)
    }

    const handleCloseBar = (event, reason) => {
      setOpenBar(false);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleAdd = async () => {
        await supabaseClient.from('Produits').insert({reference:name,fournisseur:newProdSup})
        const {data} = await supabaseClient.from("Produits").select("*,Fournisseur(name),Stock(*)").order('created_at',{ascending:false})
        console.log("Comp",data)
        setCompanies(data)
        handleClose()
        handleOpenBar(`Un Produit a été ajouté!\nRéference: ${name}.`)
        setName('')
        setNewProdSup('')
        
    };

    useEffect(()=>{
        const fn = async ()=>{
            const resp = await supabaseClient.auth.getSession()
            console.log(resp)
            if (resp.data.session === null){
                navigate("/login",{replace:true})
            }
            const {data:access,error:er} = await supabaseClient.from("Admins").select("*")
            if (access.length === 0 || er){
                //navigate('/403',{replace:true})
                console.log("Access",access)
                
            }
            const {data,error} = await supabaseClient.from("Livraison").select("*,Clients(*),LivraisonProducts(*,Produits(*))").order('created_at',{ascending:false})
            console.log("Error",error)
            console.log("First Fetch",data)
            setCompanies(data)
            const {data:fourn,error:err} = await supabaseClient.from("Fournisseur").select("*")
            setFournisseurs(fourn)
        }
        fn()
    },[])

    
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - companies.length) : 0;
    const [currentID,setCurrentID] = useState('');
    const [newProdSup,setNewProdSup] = useState('');
    const [serachName,setSerachName] = useState('')
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalDetails, setOpenModalDetails] = useState(false);
    const  handleNewProdSup = (event)=>{
        setNewProdSup(event.target.value)
    }
    const handleOpenDelete = () => setOpenModalDelete(true);
    const handleCloseDelete = () => setOpenModalDelete(false);

    const handleOpenDetails = () => setOpenModalDetails(true);
    const handleCloseDetails = () => setOpenModalDetails(false);

    const handleFilterByName = (event) => {
        setSerachName(event.target.value)
    };
    const [searchOption,setSearchOption] = useState('Clients.name')
    const handleSearchOption = (event,target)=>{
        setSearchOption(target)
    }

    const handleSearch = async ()=>{
        if(serachName.length > 0){
            console.log(searchOption)
            let data = null
            let error = null
            if (searchOption === "LivraisonProducts.Produits.reference"){
                console.log("hereeee")
                const {data:prods,error:er} = await supabaseClient.from("Produits").select("id").ilike(`reference`,`%${serachName}%`)
                let crit = []
                prods.forEach((value)=>{
                    crit.push(value.id)
                })

                const {data:resp,error:err} = await supabaseClient.from("Livraison").select("*,Clients(*),LivraisonProducts(*,Produits(*))").in("LivraisonProducts.product",crit).order('created_at',{ascending:false})
                console.log('Search',resp)
                data = resp
                error = err
                

            }else{
                console.log(searchOption)
                const {data:resp,error:err} = await supabaseClient.from("Livraison").select("*,Clients(*),LivraisonProducts(*,Produits(*))").ilike(`${searchOption}`,`%${serachName}%`).order('created_at',{ascending:false})
                console.log('Search',resp)
                data = resp
                error = err
            }
            console.log("DDDDD",data)
            let dt = []
            if (searchOption !== "num_bon"){
                
                if (error === null){
                    //console.log("DDDDD",data)
                    data.forEach((value)=>{
                        let opt = searchOption.split(".")
                        
                        if (value[opt[0]] !== null){
                            if (Array.isArray(value[opt[0]])){
                                console.log("Array",value[opt[0]])
                                if (value[opt[0]].length > 0){
                                    dt.push(value)
                                }
                            }else{
                                console.log("No Array",value[opt[0]])
                                if(value[opt[0]][opt[1]] !== undefined ){
                                    dt.push(value)
                                }
                            }
                            
                            
                        }
                    })
                }
                console.log("dt",dt)
            }else{
                dt = data
            }
            
            
            setCompanies(dt)
            
        }
        setPage(0);
    }


    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleDelete = async ()=>{
        await supabaseClient.from("Livraison").delete().eq('id',currentID)
        const {data,error} = await supabaseClient.from("Livraison").select("*,Clients(*),LivraisonProducts(*,Produits(*))").order('created_at',{ascending:false})
        handleCloseMenu()
        handleCloseDelete()
        setCompanies(data)
        console.log({data,error})
        handleOpenBar(`La Livraison a bien été supprimer.`)
    }

    const handleClearSearch = async ()=>{
        const {data,error} = await supabaseClient.from("Livraison").select("*,Clients(*),LivraisonProducts(*,Produits(*))").order('created_at',{ascending:false})
        console.error(error)
        console.log(data)
        setCompanies(data)
    }

    const [detailID,setDetailID] = useState(null)

    const bonPdf = (row)=>{
        let total = ((row.retour)?"-":"") + ` ${row.total}`
        let documentDefinition = {
            content: [
              { text: `Bon de Livraison : ${row.num_bon}`, style: 'header' },
              {text: `Total : ${total} DH(s)`,style:'subheader'},
              {text: `Client : ${row.Clients.name}`,style:'subheader'},
              {text: `Date : ${fDate(row.created_at)}`,style:'subheader'},
              {
                style: 'tableExample',
                table: {
                    widths: ["*","*", "*", "*", "*"],
                    heights: ["*","*", "*", "*", "*"],
                  body: [
                    [{text: 'Total m(s)', style: 'tableHeader',alignment: 'center'},{text: 'Produits', style: 'tableHeader',alignment: 'center'}, {text: 'Quantité m(s)', style: 'tableHeader',alignment: 'center'}, {text: 'Prix DH(s)', style: 'tableHeader',alignment: 'center'},{text: 'Sous Total DH(s)', style: 'tableHeader',alignment: 'center'}]
                  ]
                }
              },
            ],
            styles: {
              header: {
                fontSize: 18,
                bold: true,
                margin: [150, 10, 0, 5],
              },
              subheader: {
                fontSize: 16,
                bold: true,
                margin: [0, 10, 0, 5]
              },
              tableExample: {
                fontSize: 16,
                
                margin: [10, 50, 0, 15]
              },
              tableBody:{
                fontSize: 16,
              },
              tableHeader: {
                bold: true,
                fontSize: 13,
                color: 'black',
              }
            },
        };
        let tableLines = [...documentDefinition.content[4].table.body]
        let uniProds = []
        let uniqueLines = []
        row.LivraisonProducts.forEach((value)=>{
            const {quant,Produits,price,id} = value;
            const ind = uniProds.indexOf(Produits.id)
            if (ind !== -1){
                uniqueLines[ind].quant = `${uniqueLines[ind].quant} + ${quant}` 
                uniqueLines[ind].sub = uniqueLines[ind].sub + uniqueLines[ind].price * quant 
                uniqueLines[ind].totalQuant = uniqueLines[ind].totalQuant + quant 
            }else{
                uniProds.push(Produits.id)
                uniqueLines.push({
                    ref : Produits.reference,
                    quant : `${quant}`,
                    price : price,
                    sub : quant*price,
                    totalQuant:quant,
                })
            }
        })
        console.log(JSON.stringify(uniqueLines))
        uniqueLines.forEach((value)=>{
            tableLines.push([
                {text: `${value.totalQuant}`, style: 'tableBody',alignment: 'center'},
                {text: `${value.ref}`, style: 'tableBody',alignment: 'center'},
                {text: `${value.quant}`, style: 'tableBody',alignment: 'center'},
                {text: `${value.price}`, style: 'tableBody',alignment: 'center'},
                {text: `${value.sub}`, style: 'tableBody',alignment: 'center'},
            ])
        })
        documentDefinition.content[4].table.body = tableLines
        pdfMake.createPdf(documentDefinition).download(`bon_${row.num_bon}.pdf`)
    }
    
    return (
        <>
        <Helmet>
            <title> Vos Livraisons </title>
        </Helmet>
        <Snackbar open={openBar} anchorOrigin={{ vertical:"top", horizontal:'center' }} autoHideDuration={6000} onClose={handleCloseBar} onClick={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                {alertMessage}
            </Alert>
        </Snackbar>

        <Modal
            open={openModalDelete}
            onClose={handleCloseDelete}
        >
            <Container 
                sx={{
                    position: 'absolute',
                    top: '50%', // Center vertically
                    left: '50%', // Center horizontally
                    transform: 'translate(-50%, -50%)',
                    width: '90vw', // Adjust the width as needed
                    
                    overflow: "auto",
                    alignSelf:'center',
                    borderColor:'#103996',
                    borderRadius:"10px",
                    backgroundColor:'#fff',
                    marginTop:"5%"}}
            >
                <Scrollbar >
                <Stack direction="column" alignItems="center" justifyContent="space-evenly" alignContent='center' m={3} spacing={3}>
                    <Typography variant="h6" alignContent='center' textOverflow='revert'>
                        Voulez Vous Supprimer cette Livraison ?
                    </Typography>
                    
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <Typography variant="h8" alignContent='center' color="text.secondary" textAlign='center'>
                    <strong> Veuillez noter que les informations associées seront perdu et irrecuperable !</strong>
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent="space-evenly" alignContent='center' m={3} spacing={3}>
                        <Button
                            variant="contained"
                            startIcon={<DeleteForeverIcon />}
                            onClick={handleDelete}
                        >
                            OUI 
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleCloseDelete}
                        >
                            NON
                        </Button>
                    </Stack>
                </Stack>
                </Scrollbar>
            </Container>
        </Modal>
        <Modal
            open={openModalDetails}
            onClose={handleCloseDetails}
            sx={{alignItems:"center",justifyContent:'center'}}
        >
            <Container 
                sx={{
                    position: 'absolute',
                top: '50%', // Center vertically
                left: '50%', // Center horizontally
                transform: 'translate(-50%, -50%)',
                width: '90vw', // Adjust the width as needed
                
                overflow: "auto",
                    alignSelf:'center',
                    borderColor:'#103996',
                    borderRadius:"10px",
                    backgroundColor:'#fff',
                    padding:5,
                    justifySelf:'center',
                    marginTop:"5%"}}
            >
                <Scrollbar >
                <Stack direction="column" alignItems="center" justifyContent="space-evenly" alignContent='center' m={3} spacing={3}>
                    <Typography variant="h6" alignContent='center' textOverflow='revert'>
                        Table des Produits pour cette Livraison : 
                    </Typography>
                    
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <TableContainer >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align = 'center'>
                                    Réference
                                </TableCell>
                                <TableCell align = 'center'>
                                    Quantite
                                </TableCell>
                                <TableCell align = 'center'>
                                    Prix
                                </TableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {companies[detailID] ? 
                        <>{companies[detailID].LivraisonProducts.map((row,index) => {
                            const {quant,Produits,price,id} = row;
                            
                            return (
                            <TableRow hover onClick={()=>{console.log("rowClicked",id)}} key={id} tabIndex={-1}  >
                               <TableCell component="th" align="center" scope="row" padding="none">
                                    <Typography variant="subtitle2" noWrap>
                                        {Produits.reference}
                                    </Typography>
                                </TableCell>
                                
                                
                                <TableCell align="center">{quant}</TableCell>
                                <TableCell align="center">{price}</TableCell>
                                
                            </TableRow>
                            );
                        })}</>:<></>}
                        
                        </TableBody>

                        
                    </Table>
                    </TableContainer>
                </Stack>
                </Scrollbar>
            </Container>
        </Modal>
        <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
                Livraisons Passée
            </Typography>
            
            </Stack>

            <Card>
                <Container 
                    sx={{
                        alignSelf:'center',
                        borderColor:'#103996',
                        borderRadius:"10px",
                        backgroundColor:'#fff',
                        padding:3,
                        justifySelf:'center',
                        display:'grid'
                        }}
                >
                <Stack direction="row" width='100%' alignItems="center" justifyContent="space-evenly" >
                    <ToggleButtonGroup
                        style={{justifySelf:'center',display:'block'}}
                        color="primary"
                        value={searchOption}
                        exclusive
                        onChange={handleSearchOption}
                        aria-label="Search Option"
                        
                        >
                        <ToggleButton value="LivraisonProducts.Produits.reference">Par Produit</ToggleButton>
                        <ToggleButton value="Clients.name">Par Client</ToggleButton>
                        <ToggleButton value="Clients.addresse">Par Ville</ToggleButton>
                        <ToggleButton value="num_bon">Par N° de Bon</ToggleButton>
                    </ToggleButtonGroup>
                    <Button variant="contained" onClick={handleClearSearch} >
                        Effacer la recherche
                    </Button>
                </Stack>
                </Container>
                <StyledRoot>
                
                <Stack direction="row" width='100%' alignItems="center" justifyContent="space-between" >
                    <StyledSearch
                        onChange={handleFilterByName}
                        placeholder="Rechercher Une Livraison"
                    />
                    <IconButton variant="contained" onClick={handleSearch} sx={{height:'100%',marginLeft:5}} >
                        <Iconify icon="eva:search-fill" sx={{ width: 50, height: '100%' }} />
                    </IconButton>
                    
                </Stack>
                </StyledRoot>
                
                <Scrollbar>
                    <TableContainer >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align = 'center'>
                                    N° de Bon
                                </TableCell>
                                <TableCell align = 'center'>
                                    Bon
                                </TableCell>
                                <TableCell align = 'center'>
                                    Date d'Ajout
                                </TableCell>
                                <TableCell align = 'center'>
                                    Client
                                </TableCell>
                                <TableCell align = 'center'>
                                    Ville
                                </TableCell>
                                <TableCell align = 'center'>
                                    Total (DHs)
                                </TableCell>
                                <TableCell align = 'center'>
                                    Supprimer
                                </TableCell>
                                <TableCell align = 'center'>
                                    {' '}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {companies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => {
                            const {num_bon,id,created_at,total,Clients,retour} = row;
                            let style = "success.main"
                            if (retour) style = "error.main"
                            return (
                            <TableRow hover onClick={()=>{console.log("rowClicked",id)}} key={id} tabIndex={-1}  >
                               <TableCell component="th" align="center" scope="row" padding="none">
                                    <Typography variant="subtitle2" noWrap>
                                        {num_bon}
                                    </Typography>
                                </TableCell>
                                <TableCell align="center">
                                    <IconButton size="large"  color="inherit" onClick={()=>{
                                        bonPdf(row)}}>
                                        <Iconify icon={'eva:download-outline'} />
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center">{fDate(created_at)}</TableCell>
                                <TableCell align="center">{Clients.name}</TableCell>
                                <TableCell align="center">{Clients.addresse}</TableCell>
                                <TableCell align="center" sx={{color:style}}>{(retour)?"-":""}{` ${total}`}</TableCell>
                                <TableCell align="center">
                                    <IconButton size="large" sx={{ color: 'error.main' }} onClick={()=>{
                                        setCurrentID(id)
                                        handleOpenDelete()}}>
                                        <Iconify icon={'eva:trash-2-outline'} />
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center">
                                    <IconButton size="large"  color="inherit" onClick={()=>{
                                        setDetailID(index + page * rowsPerPage)
                                        handleOpenDetails()}}>
                                        <Iconify icon={'eva:arrow-ios-forward-outline'} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        </TableBody>

                        
                    </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={companies.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>

            
        </Container>
       
        </>
    );



}