import React,{ useState,useEffect } from "react";
import { Helmet } from 'react-helmet-async';
// @mui
import {
    Popover,
    Card,
    Table,
    TableRow,
    TableHead,
    MenuItem,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Button,
    Container, 
    Stack, 
    Typography, 
    Modal, 
    TextField, 
    InputAdornment, 
    IconButton, 
    Divider, 
    Snackbar,
    Toolbar,
    OutlinedInput,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { supabaseClient } from 'src/supabase';
import { useNavigate} from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';

import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

//----------------------------------------------------------------------
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
  }));
  
const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: "100%",
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));
  
// ----------------------------------------------------------------------


export default function Supplier (){
    const navigate = useNavigate();
    const [open, setOpen] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [name,setName] = useState('');
    const [addresse,setAddresse] = useState('');
    const [contact,setContact] = useState('');
    const [password,setPassword] = useState('');
    const [passwordConf,setPasswordConf] = useState('');
    const [companies,setCompanies] = useState([]);

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const [openBar, setOpenBar] = useState(false);
    const [alertMessage,setAlertMessage] = useState('');

    const handleOpenBar = (message)=>{
        setAlertMessage(message)
        setOpenBar(true)
    }

    const handleCloseBar = (event, reason) => {
      setOpenBar(false);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleAdd = async () => {
        await supabaseClient.from('Fournisseur').insert({name,addresse,contact})
        const {data} = await supabaseClient.from("Fournisseur").select("*").order('created_at',{ascending:false})
        console.log("Comp",data)
        setCompanies(data)
        handleClose()
        handleOpenBar(`Un Fournisseur a été ajouté: ${name}.`)
        setPassword('')
        setName('')
        setPasswordConf('')
        
    };

    useEffect(()=>{
        const fn = async ()=>{
            const resp = await supabaseClient.auth.getSession()
            console.log(resp)
            if (resp.data.session === null){
                navigate("/login",{replace:true})
            }
            const {data:access,error:er} = await supabaseClient.from("Admins").select("*")
            if (access.length === 0 || er){
                navigate('/403',{replace:true})
                console.log("Access",access)
                
            }
            const {data,error} = await supabaseClient.from("Fournisseur").select("*").order('created_at',{ascending:false})
            console.error(error)
            console.log(data)
            setCompanies(data)
        }
        fn()
    },[])

    
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - companies.length) : 0;
    const [currentID,setCurrentID] = useState('');
    const [associatedAccount,setAssociatedAccount] = useState('')
    const [serachName,setSerachName] = useState('')
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const handleOpenDelete = () => setOpenModalDelete(true);
    const handleCloseDelete = () => setOpenModalDelete(false);

    const handleFilterByName = (event) => {
        setSerachName(event.target.value)
    };

    const handleSearch = async ()=>{
        if(serachName.length > 0){
            const {data,error} = await supabaseClient.from("Fournisseur").select("*").ilike('name',`%${serachName}%`).order('created_at',{ascending:false})
            setCompanies(data)
        }
        setPage(0);
    }

    const handleOpenMenu = (event,id) => {
        setCurrentID(id)
        setOpen(event.currentTarget);
    };

    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleDelete = async ()=>{
        await supabaseClient.from("Fournisseur").delete().eq('id',currentID)
        const {data,error} = await supabaseClient.from("Fournisseur").select("*").order('created_at',{ascending:false})
        handleCloseMenu()
        handleCloseDelete()
        setCompanies(data)
        console.log({data,error})
        handleOpenBar(`Le Fournisseur a bien été supprimer.`)
    }

    
    return (
        <>
        <Helmet>
            <title> Vos Fournisseur </title>
        </Helmet>
        <Snackbar open={openBar} anchorOrigin={{ vertical:"top", horizontal:'center' }} autoHideDuration={6000} onClose={handleCloseBar} onClick={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                {alertMessage}
            </Alert>
        </Snackbar>
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{alignItems:"center",justifyContent:'center'}}
        >
            <Container 
                sx={{width:'90%',
                    hight:'80%',
                    alignSelf:'center',
                    borderColor:'#103996',
                    borderRadius:"10px",
                    backgroundColor:'#fff',
                    padding:5,
                    justifySelf:'center',
                    marginTop:"5%"}}
            >
                <Scrollbar >
                <Stack direction="column" alignItems="center" justifyContent="space-evenly" alignContent='center' m={1} spacing={2}>
                    <Typography variant="h6" noWrap alignContent='center'>
                        Nouveau Fournisseur
                    </Typography>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <TextField sx={{width:'50%',minWidth:250}}  name="name" label="Nom du Fournisseur" onChange={(e)=>setName(e.target.value)}/>
                    
                    <TextField sx={{width:'50%',minWidth:250}} name="addresse" label="Addresse" onChange={(e)=>setAddresse(e.target.value)}/>

                    <TextField sx={{width:'50%',minWidth:250}} name="contact" label="Contact" onChange={(e)=>setContact(e.target.value)}/>
                    
                    <Button 
                        variant="contained" 
                        onClick={handleAdd} 
                        mt={4} 
                        disabled={(name.length === 0) }
                    >
                    Ajouter Fournisseur 
                    </Button>
                </Stack>
                </Scrollbar>
            </Container>
        </Modal>
        <Modal
            open={openModalDelete}
            onClose={handleCloseDelete}
            sx={{alignItems:"center",justifyContent:'center'}}
        >
            <Container 
                sx={{width:'90%',
                    hight:'80%',
                    alignSelf:'center',
                    borderColor:'#103996',
                    borderRadius:"10px",
                    backgroundColor:'#fff',
                    padding:5,
                    justifySelf:'center',
                    marginTop:"5%"}}
            >
                <Scrollbar >
                <Stack direction="column" alignItems="center" justifyContent="space-evenly" alignContent='center' m={3} spacing={3}>
                    <Typography variant="h6" noWrap alignContent='center' textOverflow='revert'>
                        Voulez Vous Supprimer ce Fournisseur ?
                    </Typography>
                    
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <Typography variant="h8" noWrap alignContent='center' color="text.secondary" textAlign='center'>
                    <strong> Veuillez noter que les informations associées seront perdu et irrecuperable !</strong>
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent="space-evenly" alignContent='center' m={3} spacing={3}>
                        <Button
                            variant="contained"
                            startIcon={<DeleteForeverIcon />}
                            onClick={handleDelete}
                        >
                            OUI 
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleCloseDelete}
                        >
                            NON
                        </Button>
                    </Stack>
                </Stack>
                </Scrollbar>
            </Container>
        </Modal>
        <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
                Fournisseurs
            </Typography>
            <Button variant="contained" onClick={handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                Nouveau Fournisseur
            </Button>
            </Stack>

            <Card>
                <StyledRoot>
                <Stack direction="row" width='100%' alignItems="center" justifyContent="space-between" >
                    <StyledSearch
                        onChange={handleFilterByName}
                        placeholder="Rechercher Un Fournisseur"
                    />
                    <IconButton variant="contained" onClick={handleSearch} sx={{height:'100%',marginLeft:5}} >
                        <Iconify icon="eva:search-fill" sx={{ width: 50, height: '100%' }} />
                    </IconButton>
                    
                </Stack>
                </StyledRoot>
                
                <Scrollbar>
                    <TableContainer >
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align = 'center'>
                                    Nom du Fournisseur
                                </TableCell>
                                <TableCell align = 'center'>
                                    Addresse
                                </TableCell>
                                <TableCell align = 'center'>
                                    Contact
                                </TableCell>
                                <TableCell align = 'center'>
                                    Supprimer
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {companies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => {
                            const {name, addresse ,id ,contact} = row;
                            

                            return (
                            <TableRow hover key={id} tabIndex={-1}  >
                               <TableCell component="th" align="center" scope="row" padding="none">
                                    <Typography variant="subtitle2" noWrap>
                                        {name}
                                    </Typography>
                                </TableCell>

                                <TableCell align="center">{addresse}</TableCell>
                                <TableCell align="center">{contact}</TableCell>

                                <TableCell align="right">
                                    <IconButton size="large" color="error" onClick={(event)=>{
                                        setCurrentID(id)
                                        handleOpenDelete()
                                        }}>
                                        <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        </TableBody>

                        
                    </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={companies.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>

            
        </Container>
        <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleCloseMenu}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 1,
            width: 140,
            '& .MuiMenuItem-root': {
              px: 1,
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        

        <MenuItem sx={{ color: 'error.main' }} onClick={handleOpenDelete}>
          <Iconify icon={'eva:trash-2-outline'} sx={{ mr: 2 }} />
          Supprimer
        </MenuItem>
      </Popover>
        </>
    );







}