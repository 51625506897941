import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import SimpleLayout from './layouts/simple';
//
import BlogPage from './pages/BlogPage';
import UserPage from './pages/UserPage';
import LoginPage from './pages/LoginPage';
import Page404 from './pages/Page404';
import ProductsPage from './pages/ProductsPage';
import DashboardAppPage from './pages/DashboardAppPage';
import Supplier from './pages/Supplier';
import Product from './pages/Products';
import Stock from './pages/Stock'
import Livraison from './pages/Livraison';
import Clients from './pages/Clients';
import ClientDetails from './pages/ClientDetails';
import NewDelivery from './pages/NewDelivery';
import QRCode from './pages/QRCode';
import Documents from './pages/Documents';
// ----------------------------------------------------------------------

export default function Router() {
  const routes = useRoutes([
    {
      path: '/dashboard',
      element: <DashboardLayout />,
      children: [
        { element: <Navigate to="/dashboard/livraison" />, index: true },
        { path: 'products', element: <Product /> },
        { path: 'supplier', element: <Supplier /> },
        { path: 'stock', element: <Stock /> },
        { path: 'livraison', element: <Livraison /> },
        { path: 'clients', element: <Clients /> },
        { path: 'clientdetails', element: <ClientDetails /> },
        { path: 'newlivraison', element: <NewDelivery /> },
        { path: 'collections', element: <QRCode /> },
        { path: 'documents', element: <Documents /> },
      ],
    },
    {
      path: 'login',
      element: <LoginPage />,
    },
    {
      element: <SimpleLayout />,
      children: [
        { element: <Navigate to="/dashboard/livraison" />, index: true },
        { path: '404', element: <Page404 /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    {
      path: '*',
      element: <Navigate to="/dashboard/livraison" replace />,
    },
  ]);

  return routes;
}
