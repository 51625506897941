import React,{ useState,useEffect } from "react";
import { Helmet } from 'react-helmet-async';
import { faker } from '@faker-js/faker';
// @mui
import { useTheme } from '@mui/material/styles';
// components

import {
  Grid,
  Card,
  Table,
  TableRow,
  TableHead,
  MenuItem,
  TableBody,
  TableCell,
  TableContainer,
  TablePagination,
  Button,
  Container, 
  Stack, 
  Typography, 
  Modal, 
  TextField, 
  InputAdornment, 
  InputLabel ,
  FormControl ,
  Select ,
  IconButton, 
  Divider, 
  Snackbar,
  Toolbar,
  OutlinedInput,
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { supabaseClient } from 'src/supabase';
import { useNavigate,useSearchParams} from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import {fDate} from '../utils/formatTime'
// sections
import {
  AppWidgetSummary,
} from '../sections/@dashboard/app';

// ----------------------------------------------------------------------

//----------------------------------------------------------------------
const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
  height: 96,
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(0, 1, 0, 3),
}));

const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
width: "100%",
transition: theme.transitions.create(['box-shadow', 'width'], {
  easing: theme.transitions.easing.easeInOut,
  duration: theme.transitions.duration.shorter,
}),
'&.Mui-focused': {
  width: "100%",
  boxShadow: theme.customShadows.z8,
},
'& fieldset': {
  borderWidth: `1px !important`,
  borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
},
}));
export default function ClientDetails() {
  const theme = useTheme();
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
    const [open, setOpen] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const [name,setName] = useState('');
    const [companies,setCompanies] = useState([]);
    const [fournisseurs,setFournisseurs] = useState([]);

    const handleOpen = () => setOpenModal(true);
    const handleClose = () => setOpenModal(false);

    const [openBar, setOpenBar] = useState(false);
    const [alertMessage,setAlertMessage] = useState('');

    const handleOpenBar = (message)=>{
        setAlertMessage(message)
        setOpenBar(true)
    }

    const handleCloseBar = (event, reason) => {
      setOpenBar(false);
    };

    const handleCloseMenu = () => {
        setOpen(null);
    };

    const handleAdd = async () => {
        await supabaseClient.from('Produits').insert({reference:name,fournisseur:newProdSup})
        const {data} = await supabaseClient.from("Produits").select("*,Fournisseur(name),Stock(*)").order('created_at',{ascending:false})
        console.log("Comp",data)
        setCompanies(data)
        handleClose()
        handleOpenBar(`Un Produit a été ajouté!\nRéference: ${name}.`)
        setName('')
        setNewProdSup('')
        
    };
    const [clientID,setClientID] = useState('')
    const [money,setMoney] = useState({sells:0,retour:0})
    useEffect(()=>{
        let prod = searchParams.get('id')
        if (prod === null){
            navigate('/dashboard/clients')
        }else{
            setClientID(prod)
        }
        const fn = async ()=>{
            const resp = await supabaseClient.auth.getSession()
            console.log(resp)
            if (resp.data.session === null){
                navigate("/login",{replace:true})
            }
            const {data:access,error:er} = await supabaseClient.from("Admins").select("*")
            if (access.length === 0 || er){
                navigate('/403',{replace:true})
                console.log("Access",access)
                
            }
            const {data,error} = await supabaseClient.from("Livraison").select("*,Clients(*),LivraisonProducts(*,Produits(*))").eq("Clients.id",prod).order('created_at',{ascending:false})
            console.log("Error",error)
            console.log("First Fetch",data)
            
            let sells = 0
            let retour = 0
            let datast = []
            data.forEach((val)=>{
                if(val.Clients){
                    if(val.retour){
                        retour = retour + val.total
                      }else{
                        sells= sells + val.total
                      }
                    datast.push(val)
                }
              
            })
            setCompanies(datast)
            console.log("retttt",retour)
            setMoney({sells,retour})
            const {data:fourn,error:err} = await supabaseClient.from("Fournisseur").select("*")
            setFournisseurs(fourn)
        }
        fn()
    },[])

    
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - companies.length) : 0;
    const [currentID,setCurrentID] = useState('');
    const [newProdSup,setNewProdSup] = useState('');
    const [serachName,setSerachName] = useState('')
    const [openModalDelete, setOpenModalDelete] = useState(false);
    const [openModalDetails, setOpenModalDetails] = useState(false);
    const  handleNewProdSup = (event)=>{
        setNewProdSup(event.target.value)
    }
    const handleOpenDelete = () => setOpenModalDelete(true);
    const handleCloseDelete = () => setOpenModalDelete(false);

    const handleOpenDetails = () => setOpenModalDetails(true);
    const handleCloseDetails = () => setOpenModalDetails(false);

    const handleFilterByName = (event) => {
        setSerachName(event.target.value)
    };
    const [searchOption,setSearchOption] = useState('Clients.name')
    const handleSearchOption = (event,target)=>{
        setSearchOption(target)
    }

    const handleSearch = async ()=>{
        if(serachName.length > 0){
            console.log(searchOption)
            let data = null
            let error = null
            if (searchOption === "LivraisonProducts.Produits.reference"){
                console.log("hereeee")
                const {data:prods,error:er} = await supabaseClient.from("Produits").select("id").ilike(`reference`,`%${serachName}%`)
                let crit = []
                prods.forEach((value)=>{
                    crit.push(value.id)
                })

                const {data:resp,error:err} = await supabaseClient.from("Livraison").select("*,Clients(*),LivraisonProducts(*,Produits(*))").in("LivraisonProducts.product",crit).order('created_at',{ascending:false})
                console.log('Search',resp)
                data = resp
                error = err
                

            }else{
                const {data:resp,error:err} = await supabaseClient.from("Livraison").select("*,Clients(*),LivraisonProducts(*,Produits(*))").ilike(`${searchOption}`,`%${serachName}%`).order('created_at',{ascending:false})
                console.log('Search',resp)
                data = resp
                error = err
            }
            console.log("DDDDD",data)
            let dt = []
            if (error === null){
                //console.log("DDDDD",data)
                data.forEach((value)=>{
                    let opt = searchOption.split(".")
                    
                    if (value[opt[0]] !== null){
                        if (Array.isArray(value[opt[0]])){
                            console.log("Array",value[opt[0]])
                            if (value[opt[0]].length > 0){
                                dt.push(value)
                            }
                        }else{
                            console.log("No Array",value[opt[0]])
                            if(value[opt[0]][opt[1]] !== undefined ){
                                dt.push(value)
                            }
                        }
                        
                        
                    }
                })
            }
            console.log("dt",dt)
            
            setCompanies(dt)
            
        }
        setPage(0);
    }


    const handleChangeRowsPerPage = (event) => {
        setPage(0);
        setRowsPerPage(parseInt(event.target.value, 10));
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleDelete = async ()=>{
        await supabaseClient.from("Livraison").delete().eq('id',currentID)
        const {data,error} = await supabaseClient.from("Livraison").select("*,Clients(*),LivraisonProducts(*,Produits(*))").order('created_at',{ascending:false})
        handleCloseMenu()
        handleCloseDelete()
        setCompanies(data)
        console.log({data,error})
        handleOpenBar(`La Livraison a bien été supprimer.`)
    }

    const handleClearSearch = async ()=>{
        const {data,error} = await supabaseClient.from("Livraison").select("*,Clients(*),LivraisonProducts(*,Produits(*))").order('created_at',{ascending:false})
        console.error(error)
        console.log(data)
        setCompanies(data)
    }

    const [detailID,setDetailID] = useState(null)
    const [typeLiv,setTypeLiv] = useState(false)
    const handleOwnerChange = (event,target)=>{
      setTypeLiv(target)
  }

  

  return (
    <>
      <Helmet>
        <title> Détail Client </title>
      </Helmet>
      <Snackbar open={openBar} anchorOrigin={{ vertical:"top", horizontal:'center' }} autoHideDuration={6000} onClose={handleCloseBar} onClick={handleClose}>
            <Alert onClose={handleClose} severity="success" sx={{ width: '100%' }}>
                {alertMessage}
            </Alert>
        </Snackbar>
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{alignItems:"center",justifyContent:'center'}}
        >
            <Container 
                sx={{width:'90%',
                    hight:'80%',
                    alignSelf:'center',
                    borderColor:'#103996',
                    borderRadius:"10px",
                    backgroundColor:'#fff',
                    padding:5,
                    justifySelf:'center',
                    marginTop:"5%"}}
            >
                <Scrollbar >
                <Stack direction="column" alignItems="center" justifyContent="space-evenly" alignContent='center' m={1} spacing={2}>
                    <Typography variant="h6" noWrap alignContent='center'>
                        Nouvelle Livraison
                    </Typography>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <ToggleButtonGroup
                        style={{justifySelf:'center',display:'block'}}
                        color="primary"
                        value={typeLiv}
                        exclusive
                        onChange={handleOwnerChange}
                        aria-label="Search Option"
                        
                        >
                        <ToggleButton value={false}>Nouvelle Vente</ToggleButton>
                        <ToggleButton value={true}>Retour</ToggleButton>
                    </ToggleButtonGroup>
                    <TextField sx={{width:'50%',minWidth:250}}  name="name" label="Réference du Produit" onChange={(e)=>setName(e.target.value)}/>
                    
                    <FormControl variant="filled" sx={{ width:'50%',minWidth:250}}>
                        <InputLabel id="demo-simple-select-filled-label">Fournisseur</InputLabel>
                        <Select
                        labelId="demo-simple-select-filled-label"
                        id="demo-simple-select-filled"
                        value={newProdSup}
                        onChange={handleNewProdSup}
                        >
                        {fournisseurs.map((value,index)=>{
                            return(<MenuItem value={value.id}>{value.name}</MenuItem>)
                        })}
                        </Select>
                    </FormControl>

                    
                    <Button 
                        variant="contained" 
                        onClick={handleAdd} 
                        mt={4} 
                        disabled={(name.length === 0) || (newProdSup.length === 0) }
                    >
                    Livrer 
                    </Button>
                </Stack>
                </Scrollbar>
            </Container>
        </Modal>
        <Modal
            open={openModalDelete}
            onClose={handleCloseDelete}
            sx={{alignItems:"center",justifyContent:'center'}}
        >
            <Container 
                sx={{width:'90%',
                    hight:'80%',
                    alignSelf:'center',
                    borderColor:'#103996',
                    borderRadius:"10px",
                    backgroundColor:'#fff',
                    padding:5,
                    justifySelf:'center',
                    marginTop:"5%"}}
            >
                <Scrollbar >
                <Stack direction="column" alignItems="center" justifyContent="space-evenly" alignContent='center' m={3} spacing={3}>
                    <Typography variant="h6" noWrap alignContent='center' textOverflow='revert'>
                        Voulez Vous Supprimer cette Livraison ?
                    </Typography>
                    
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <Typography variant="h8" noWrap alignContent='center' color="text.secondary" textAlign='center'>
                    <strong> Veuillez noter que les informations associées seront perdu et irrecuperable !</strong>
                    </Typography>
                    <Stack direction="row" alignItems="center" justifyContent="space-evenly" alignContent='center' m={3} spacing={3}>
                        <Button
                            variant="contained"
                            startIcon={<DeleteForeverIcon />}
                            onClick={handleDelete}
                        >
                            OUI 
                        </Button>
                        <Button
                            variant="contained"
                            onClick={handleCloseDelete}
                        >
                            NON
                        </Button>
                    </Stack>
                </Stack>
                </Scrollbar>
            </Container>
        </Modal>
        <Modal
            open={openModalDetails}
            onClose={handleCloseDetails}
            sx={{alignItems:"center",justifyContent:'center'}}
        >
            <Container 
                sx={{width:'90%',
                    hight:'80%',
                    alignSelf:'center',
                    borderColor:'#103996',
                    borderRadius:"10px",
                    backgroundColor:'#fff',
                    padding:5,
                    justifySelf:'center',
                    marginTop:"5%"}}
            >
                <Scrollbar >
                <Stack direction="column" alignItems="center" justifyContent="space-evenly" alignContent='center' m={3} spacing={3}>
                    <Typography variant="h6" noWrap alignContent='center' textOverflow='revert'>
                        Table des Produits pour cette Livraison : 
                    </Typography>
                    
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align = 'center'>
                                    Réference
                                </TableCell>
                                <TableCell align = 'center'>
                                    Quantite
                                </TableCell>
                                <TableCell align = 'center'>
                                    Prix
                                </TableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {companies[detailID] ? 
                        <>{companies[detailID].LivraisonProducts.map((row,index) => {
                            const {quant,Produits,price,id} = row;
                            
                            return (
                            <TableRow hover onClick={()=>{console.log("rowClicked",id)}} key={id} tabIndex={-1}  >
                               <TableCell component="th" align="center" scope="row" padding="none">
                                    <Typography variant="subtitle2" noWrap>
                                        {Produits.reference}
                                    </Typography>
                                </TableCell>
                                
                                
                                <TableCell align="center">{quant}</TableCell>
                                <TableCell align="center">{price}</TableCell>
                                
                            </TableRow>
                            );
                        })}</>:<></>}
                        
                        </TableBody>

                        
                    </Table>
                    </TableContainer>
                </Stack>
                </Scrollbar>
            </Container>
        </Modal>
      <Container >

        <Grid container spacing={5} mb={5}>
          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Vente Ce Mois (DHs)" total={money.sells} color='success' icon={'ant-design:dollar-outlined'} />
          </Grid>

          

          <Grid item xs={12} sm={6} md={3}>
            <AppWidgetSummary title="Retour Ce Mois (DHs)" total={money.retour} color="error" icon={'ant-design:dollar-outlined'} />
          </Grid>
        </Grid>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Typography variant="h4" gutterBottom>
                Livraisons
            </Typography>
            <Button variant="contained" onClick={()=>navigate(`/dashboard/newlivraison?id=${clientID}`)} startIcon={<Iconify icon="eva:plus-fill" />}>
                Nouvelle Livraison
            </Button>
          </Stack>
          <Card>
                
                
                <Scrollbar>
                    <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align = 'center'>
                                    Numero de Bon
                                </TableCell>
                                <TableCell align = 'center'>
                                    Date d'Ajout
                                </TableCell>
                                <TableCell align = 'center'>
                                    Client
                                </TableCell>
                                <TableCell align = 'center'>
                                    Ville
                                </TableCell>
                                <TableCell align = 'center'>
                                    Total (DHs)
                                </TableCell>
                                <TableCell align = 'center'>
                                    {' '}
                                </TableCell>
                                <TableCell align = 'center'>
                                    {' '}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {companies.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row,index) => {
                            const {num_bon,id,created_at,total,Clients,retour} = row;
                            let style = "success.main"
                            if (retour) style = "error.main"
                            return (
                            <TableRow hover onClick={()=>{console.log("rowClicked",id)}} key={id} tabIndex={-1}  >
                               <TableCell component="th" align="center" scope="row" padding="none">
                                    <Typography variant="subtitle2" noWrap>
                                        {num_bon}
                                    </Typography>
                                </TableCell>
                                
                                <TableCell align="center">{fDate(created_at)}</TableCell>
                                <TableCell align="center">{(Clients)?Clients.name:""}</TableCell>
                                <TableCell align="center">{(Clients)?Clients.addresse:""}</TableCell>
                                <TableCell align="center" sx={{color:style}}>{(retour)?"-":""}{` ${total}`}</TableCell>
                                <TableCell align="center">
                                    <IconButton size="large" sx={{ color: 'error.main' }} onClick={()=>{
                                        setCurrentID(id)
                                        handleOpenDelete()}}>
                                        <Iconify icon={'eva:trash-2-outline'} />
                                    </IconButton>
                                </TableCell>
                                <TableCell align="center">
                                    <IconButton size="large"  color="inherit" onClick={()=>{
                                        setDetailID(index + page * rowsPerPage)
                                        handleOpenDetails()}}>
                                        <Iconify icon={'eva:arrow-ios-forward-outline'} />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                            );
                        })}
                        {emptyRows > 0 && (
                            <TableRow style={{ height: 53 * emptyRows }}>
                            <TableCell colSpan={6} />
                            </TableRow>
                        )}
                        </TableBody>

                        
                    </Table>
                    </TableContainer>
                </Scrollbar>

                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={companies.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Card>

        


      </Container>
    </>
  );
}
