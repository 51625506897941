import React,{ useState,useEffect } from "react";
import { Helmet } from 'react-helmet-async';
// @mui
import {
    Popover,
    Card,
    Table,
    TableRow,
    TableHead,
    MenuItem,
    TableBody,
    TableCell,
    TableContainer,
    TablePagination,
    Button,
    Container, 
    Stack, 
    Typography, 
    Modal, 
    TextField, 
    InputAdornment, 
    IconButton, 
    Divider, 
    Snackbar,
    Toolbar,
    OutlinedInput,
    Autocomplete
} from '@mui/material';
import MuiAlert from '@mui/material/Alert';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { supabaseClient } from 'src/supabase';
import { useNavigate, useSearchParams} from 'react-router-dom';
import { styled, alpha } from '@mui/material/styles';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';

//----------------------------------------------------------------------
const Alert = React.forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});
// ----------------------------------------------------------------------

const StyledRoot = styled(Toolbar)(({ theme }) => ({
    height: 96,
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing(0, 1, 0, 3),
  }));
  
const StyledSearch = styled(OutlinedInput)(({ theme }) => ({
  width: "100%",
  transition: theme.transitions.create(['box-shadow', 'width'], {
    easing: theme.transitions.easing.easeInOut,
    duration: theme.transitions.duration.shorter,
  }),
  '&.Mui-focused': {
    width: "100%",
    boxShadow: theme.customShadows.z8,
  },
  '& fieldset': {
    borderWidth: `1px !important`,
    borderColor: `${alpha(theme.palette.grey[500], 0.32)} !important`,
  },
}));
  
// ----------------------------------------------------------------------


export default function NewDelivery (){
    const navigate = useNavigate();
    const [searchParams, setSearchParams] = useSearchParams();
    const [clientID,setClientID] = useState('')
    const [clientDetails,setClientDetails] = useState({name:'',addresse:""})
    const [openBar, setOpenBar] = useState(false);
    const [alertMessage,setAlertMessage] = useState('');

    const handleOpenBar = (message)=>{
        setAlertMessage(message)
        setOpenBar(true)
    }

    const handleCloseBar = (event, reason) => {
      setOpenBar(false);
    };

    const [commande,setCommande] = useState([])
    const [commIDs,setCommIDs] = useState([])

    const [availableProd,setAvailableProd] = useState([])
    const [allProd,setAllProd] = useState([])
    const [gammes,setGammes] = useState([])
    const [prodsLiv,setProdsLiv] = useState([])
    const [roulNbr,setRoulNbr] = useState(0)
    const handleAddRouleau = async (prodID) => {
        await reset(prodID)
        setRoulNbr(roulNbr + 1)
        setProdsLiv([...prodsLiv,{
            quant:0,
            typeSell:true,
            product:{
                label:"",
            }
            

        }])

    }

    useEffect(()=>{
        let prod = searchParams.get('id')
        if (prod === null){
            navigate('/dashboard/clients')
        }else{
            setClientID(prod)
        }
        const fn = async ()=>{
            const resp = await supabaseClient.auth.getSession()
            console.log(resp)
            if (resp.data.session === null){
                navigate("/login",{replace:true})
            }
            const {data:access,error:er} = await supabaseClient.from("Admins").select("*")
            if (access.length === 0 || er){
                navigate('/403',{replace:true})
                console.log("Access",access)
                
            }
            const {data,error} = await supabaseClient.from("Clients").select("*").eq('id',prod).order('created_at',{ascending:false})
            console.error(error)
            console.log(data)
            if (data.length > 0){
                setClientDetails(data[0])
            }else{
                navigate('/dashboard/clients')
            }
            
            
        }
        fn()
    },[])
    
    const [typeLiv,setTypeLiv] = useState(false)
    const handleTypeChange = (event,target)=>{
      setTypeLiv(target)
    }

    const [typeSell,setTypeSell] = useState(true)
    const handleTypeSellChange = (event,target)=>{
        setTypeSell(target)
    }



    const [openModal, setOpenModal] = useState(false);
    const handleOpen = async () => {
        await resetGamme()
        
        setOpenModal(true);
    }
    const handleClose = () => {
        setProd(null)
        setRoulNbr(0)
        setCommIDs([])
        setProdsLiv([])
        setOpenModal(false)
    };

    const [openModalRetour, setOpenModalRetour] = useState(false);
    const handleOpenRetour = async () => {
        await resetRetour()
        setOpenModalRetour(true);
    }
    const handleCloseRetour = () => setOpenModalRetour(false);

    const handleAutoComplete = async (event)=>{
        if(event.target.value){
            if(event.target.value.length > 2 ){
                const {data:st,error:err} = await supabaseClient.from("Stock")
                .select("*,Produits(reference,id)")
                .ilike(`Produits.reference`,`%${event.target.value}%`)
                .gt("quant",0)
                .order('created_at',{ascending:false})
                console.log("sttt",st)
                let nst = []
                st.forEach((option) => {
                    if(option.Produits){
                        let contQuant = 0
                        console.log("Option",option)
                        let control = commIDs.indexOf(option.id)
                        let controlG = golbalIDs.indexOf(option.id)
                        console.log(commIDs)
                        console.log("Controlll",controlG)

                        if(control !== -1){
                            console.log("control",prodsLiv[control])
                            
                           contQuant = prodsLiv[control].toSell
                        }

                        if (controlG !== -1){
                            contQuant = commande[controlG].toSell
                        }

                        let base = `${option.Produits.reference} -${option.quant - contQuant} m(s)-`
                        if (option.owner !== "depot") base = base + `(${option.owner})`
                        if (option.quant-contQuant>0){
                            nst.push({label:base,
                                id:option.Produits.id,
                                quant:option.quant-contQuant,
                                reference:option.Produits.reference,
                                stockId:option.id,
                                owner:(option.owner === "depot")?"Dépot":option.owner,
                            })
                        }
                        
                    }
                })
                setAvailableProd(nst)
            }
        }
        
    }
    const handleAutoCompleteGamme = async (event)=>{
        if(event.target.value){
            if(event.target.value.length > 2 ){
                const {data} = await supabaseClient.from("Collections").select("*,Produits(id)").ilike("name",`%${event.target.value}%`)
                .order('created_at',{ascending:false})
                let gam = []
                data.forEach((value)=>{
                    let cm = []
                    value.Produits.forEach((val)=>{
                    cm.push(val.id)
                    })
                    gam.push({
                        id: value.id,
                        label : value.name,
                        produits : cm,
                    })

                })
                setGammes(gam)
                
            }
        }

    }
    const resetGamme = async ()=>{
        const {data} = await supabaseClient.from("Collections").select("*,Produits(id)")
                        .order('created_at',{ascending:false})
        console.log("Gammes",data)
        let gam = []
        data.forEach((value)=>{
            let cm = []
            value.Produits.forEach((val)=>{
              cm.push(val.id)
            })
            gam.push({
                id: value.id,
                label : value.name,
                produits : cm,
            })

        })
        setGammes(gam)

    }

    const handleAutoCompleteRetour = async (event)=>{
        if(event.target.value){
            let crit = event.target.value.trim()
            if(crit.length > 2 ){
                
                const {data:st,error:err} = await supabaseClient.from("Produits")
                .select("*")
                .ilike(`reference`,`%${crit}%`)
                .order('created_at',{ascending:false})
                
                let nst = []
                st.forEach((option) => {
                        let base = `${option.reference}`
                        
                        nst.push({label:base,id:option.id}) 
                    
                })
                console.log("Auto",nst)
                setAllProd(nst)
            }
        }
        
    }
    const resetRetour = async ()=>{
        const {data:st,error:err} = await supabaseClient.from("Produits")
                .select("*")
                .order('created_at',{ascending:false})
                console.log("sttt",st)
                let nst = []
                st.forEach((option) => {
                    let base = `${option.reference}`
                    
                    nst.push({label:base,id:option.id}) 
                
            })
            setAllProd(nst)
    }

    const [golbalIDs,setGlobalIDs] = useState([])

    const reset = async (prodID)=>{
        const {data:st,error:err} = await supabaseClient.from("Stock")
                .select("*,Produits(reference,id)")
                .in("product",prodID)
                .gt("quant",0)
                .order('created_at',{ascending:false})
                console.error("Err",err)
                let nst = []
                st.forEach((option) => {
                    if(option.Produits){
                        let contQuant = 0
                        let control = commIDs.indexOf(option.id)
                        let controlG = golbalIDs.indexOf(option.id)

                        if(control !== -1){
                            
                           contQuant = prodsLiv[control].toSell
                        }

                        if (controlG !== -1){
                            contQuant = commande[controlG].toSell
                        }

                        let base = `${option.Produits.reference} -${option.quant - contQuant} m(s)-`
                        if (option.owner !== "depot") base = base + `(${option.owner})`
                        if (option.quant-contQuant>0){
                            nst.push({label:base,
                                id:option.Produits.id,
                                quant:option.quant-contQuant,
                                reference:option.Produits.reference,
                                stockId:option.id,
                                owner:(option.owner === "depot")?"Dépot":option.owner,
                            })
                        }
                        
                    }
                })
                setAvailableProd(nst)
    }

    const [quant,setQuant] = useState(0)
    const [price,setPrice] = useState(0)
    const [prod,setProd] = useState(null)

    const [livTotal,setLivTotal] = useState(0)

    const [num_bon,setNum_bon] = useState("")

    const validerLivraison = async ()=>{
        const {data,error} = await supabaseClient.from("Livraison").insert({client:clientID,total:livTotal,retour:typeLiv,num_bon}).select("id")
        console.log("dataLiv",data)
        console.log("dataEroor",error)
        let det = []
        commande.forEach((value)=>{
            det.push({
                product:value.id,
                quant:value.toSell,
                price:value.price,
                livraison:data[0].id,
            })
        })
        console.log(commande,"Commande")
        const {error:err} = await supabaseClient.from("LivraisonProducts").insert(det).select("*")
        console.log(err)
        if(typeLiv){    
            //Retour
            let ret = []
            commande.forEach((value)=>{
                ret.push({
                    product:value.id,
                    quant:value.toSell,
                })
            })
            await supabaseClient.from("Stock").insert(ret)

        }else{
            //Vente
            commande.forEach(async (value)=>{
                if(value.quant === value.toSell){
                    await supabaseClient.from("Stock").delete().eq("id",value.stock)
                }else{
                    await supabaseClient.from("Stock").update({quant:value.quant - value.toSell}).eq("id",value.stock)
                }
                
            })
            
        }
        setAlertMessage('Livraison a été traitée !')
        setGlobalIDs([])
        setCommande([])
        setLivTotal(0)
        handleOpenBar('Livraison a été traitée !')
    }

    const hadnleDeleteQuant = (index)=>{
        console.log("index Del",index)
        setRoulNbr(roulNbr - 1)
        let cpCommIds = [...commIDs]
        cpCommIds.splice(index,1)
        setCommIDs(cpCommIds)
        let cpProds = [...prodsLiv]
        cpProds.splice(index,1)
        setProdsLiv(cpProds)

    }

    return (
        <>
        <Helmet>
            <title> Nouvelle Livraison</title>
        </Helmet>
        <Snackbar open={openBar} anchorOrigin={{ vertical:"top", horizontal:'center' }} autoHideDuration={6000} onClose={handleCloseBar} onClick={handleClose}>
            <Alert onClose={handleCloseBar} severity="success" sx={{ width: '100%' }}>
                {alertMessage}
            </Alert>
        </Snackbar>
        <Modal
            open={openModal}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{alignItems:"center",justifyContent:'center'}}
        >
            <Container 
                sx={{
                    maxHeight:"70%",
                    overflow:'scroll',
                    display:'block',
                    alignSelf:'center',
                    borderColor:'#103996',
                    borderRadius:"10px",
                    backgroundColor:'#fff',
                    padding:5,
                    justifySelf:'center',
                    marginTop:"5%"}}
            >
                <Scrollbar >
                <Stack direction="column" alignItems="center" justifyContent="space-evenly" alignContent='center' m={1} spacing={2}>
                    <Typography variant="h6"  alignContent='center'>
                        Ajouter Des Produits 
                    </Typography>
                    <Divider sx={{ borderStyle: 'dashed' }} />

                    <Autocomplete
                        sx={{width:'50%',minWidth:250}}
                        onChange={(event,value)=>{
                            setProd(value)
                            console.log(value.produits)
                            reset(value.produits)
                        }}
                        options={gammes}
                        onInputChange={handleAutoCompleteGamme}
                        renderInput={(params) => (
                        <TextField
                            
                            {...params}
                            label="Gamme"
                            InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            }}
                        />
                        )}
                    
                    />

                    <TextField sx={{width:'50%',minWidth:250}} inputProps={{type:"number"}} name="price" label="Prix au métre" onChange={(e)=>setPrice(parseFloat(e.target.value))}/>
                    {prodsLiv.map((val,index)=>{

                        return(<>
                                {(index !== 0)?<Divider sx={{ borderStyle: 'solid',borderWidth:"2px" ,minWidth:250 , width:"50%"}} />:<></>}
                                <Stack direction="row"  justifyContent="space-evenly" width={"50%"} spacing={1} key={index}>
                                    <Autocomplete
                                        sx={{width:"100%",minWidth:250}}
                                        onChange={(event,value)=>{
                                            let cpProds = [...prodsLiv]
                                            cpProds[index].product = value
                                            console.log("Change",value.stockId)
                                            setCommIDs([...commIDs,value.stockId])
                                            setProdsLiv(cpProds)
                                            reset(prod.produits)
                                        }}
                                        options={availableProd}
                                        inputValue={prodsLiv[index].product.label}
                                        onInputChange={handleAutoComplete}
                                        onClose={(event,reason)=>{
                                            if(reason !== "selectOption"){
                                                reset(prod.produits)
                                            }
                                        }}
                                        renderInput={(params) => (
                                        <TextField
                                            {...params}
                                            label="Rouleau"
                                            InputProps={{
                                            ...params.InputProps,
                                            type: 'search',
                                            }}
                                        />
                                        )}
                                    />
                                    <IconButton size="large" sx={{ color: 'error.main' }} onClick={()=>{hadnleDeleteQuant(index)}}>
                                        <Iconify icon={'eva:trash-2-outline'} />
                                    </IconButton>
                                </Stack>
                                <ToggleButtonGroup
                                    style={{justifySelf:'center',display:'block'}}
                                    color="primary"
                                    value={prodsLiv[index].typeSell}
                                    exclusive
                                    onChange={(event,value)=>{
                                        let cpProds = [...prodsLiv]
                                        cpProds[index].typeSell = value
                                        setProdsLiv(cpProds)
                                    }}
                                    aria-label="Search Option"

                                >
                                    <ToggleButton value={false}>Detail</ToggleButton>
                                    <ToggleButton value={true}>Rouleaux Entier</ToggleButton>
                                </ToggleButtonGroup>
                                {(prodsLiv[index].typeSell)?<></>:
                                <TextField 
                                    sx={{width:'50%',minWidth:250}} 
                                    inputProps={{type:"number"}} 
                                    name="quant" 
                                    label="Quantité" 
                                    onChange={(e)=>{
                                        let cpProds = [...prodsLiv]
                                        cpProds[index].toSell = parseFloat(e.target.value)
                                        setProdsLiv(cpProds)
                                        reset(prod.produits)
                                    }}
                                />
                                }
                                {(index !== 0 && index === prodsLiv.length)?
                                <Divider sx={{ borderStyle: 'solid',borderWidth:"2px" ,minWidth:250 , width:"50%"}} />:<></>}
                                
                            </>  
                        )
                    })}

                    <IconButton size="large" sx={{ color: 'success.main' ,margin:4}} onClick={()=>{handleAddRouleau(prod.produits)}} disabled={ prod === null}>
                        <Iconify icon={'eva:plus-circle-outline'} />
                    </IconButton>

                    
                    
                    

                    
                    <Button 
                        variant="contained" 
                        onClick={()=>{
                            let subComm = []
                            let subIDs = []
                            let total = 0
                            prodsLiv.forEach((value)=>{
                                let cm = {
                                    reference:value.product.reference,
                                    id:value.product.id,
                                    price,
                                    quant:value.product.quant,
                                    toSell:(value.typeSell)?value.product.quant:value.toSell,
                                    stock:value.product.stockId,
                                    stockOwner:value.product.owner,
                                }
                                subComm.push(cm)
                                subIDs.push(cm.stock)
                                total = total + price * cm.toSell

                            })
                            
                            console.log("prod",subIDs)
                            console.log("prodsLiv",prodsLiv)
                            setGlobalIDs([...golbalIDs,...subIDs])
                            setCommande([...commande,...subComm])
                            //setPrice(0)
                            //setQuant(0)
                            //setProd(null)
                            setLivTotal(livTotal + total)
                            handleClose()
                        }} 
                        mt={4} 
                        disabled={(price === 0) || (prod === null) || (typeSell === false && (quant === 0 || quant > prod.quant) )}
                    >
                    Ajouter
                    </Button>
                </Stack>
                </Scrollbar>
            </Container>
        </Modal>
        <Modal
            open={openModalRetour}
            onClose={handleCloseRetour}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{alignItems:"center",justifyContent:'center'}}
        >
            <Container 
                sx={{
                    alignSelf:'center',
                    borderColor:'#103996',
                    borderRadius:"10px",
                    backgroundColor:'#fff',
                    padding:5,
                    justifySelf:'center',
                    marginTop:"5%"}}
            >
                <Scrollbar >
                <Stack direction="column" alignItems="center" justifyContent="space-evenly" alignContent='center' m={1} spacing={2}>
                    <Typography variant="h6"  alignContent='center'>
                        Ajouter Un Produit pour Un Retour
                    </Typography>
                    <Divider sx={{ borderStyle: 'dashed' }} />
                    <Autocomplete
                        sx={{width:'50%',minWidth:250}}
                        onChange={(event,value)=>{setProd(value)}}
                        options={allProd}
                        onInputChange={handleAutoCompleteRetour}
                        renderInput={(params) => (
                        <TextField
                            
                            {...params}
                            label="Produit"
                            InputProps={{
                            ...params.InputProps,
                            type: 'search',
                            }}
                        />
                        )}
                    
                    />
                    
                    <TextField sx={{width:'50%',minWidth:250}} inputProps={{type:"number"}} name="quant" label="Quantité" onChange={(e)=>setQuant(parseFloat(e.target.value))}/>
                    
                    <TextField sx={{width:'50%',minWidth:250}} inputProps={{type:"number"}} name="price" label="Prix au métre" onChange={(e)=>setPrice(parseFloat(e.target.value))}/>
                    

                    
                    <Button 
                        variant="contained" 
                        onClick={()=>{
                            let cm = {
                                reference:prod.label,
                                id:prod.id,
                                price,
                                quant:quant,
                                toSell:quant,
                                stock:"",
                                stockOwner:"",
                            }
                            setCommIDs([...commIDs,prod.id])
                            setCommande([...commande,cm])
                            setPrice(0)
                            setQuant(0)
                            setProd(null)
                            setLivTotal(livTotal + cm.toSell*cm.price)
                            handleCloseRetour()
                        }} 
                        mt={4} 
                        disabled={(price === 0) || (prod === null) || (quant === 0  )}
                    >
                    Ajouter
                    </Button>
                </Stack>
                </Scrollbar>
            </Container>
        </Modal>
        <Container>
            <Stack direction="column" alignItems="start" justifyContent="space-evenly" alignContent='start' m={1} spacing={1}>
                <Typography variant="h4" gutterBottom>
                    Livraison pour : {clientDetails.name}
                </Typography>
                <Typography variant="h6" gutterBottom>
                    Ville : {clientDetails.addresse}
                </Typography>
            </Stack>
            <Stack direction="row" alignItems="center" justifyContent="space-evenly" alignContent='center' m={5} spacing={2}>
                
                <ToggleButtonGroup
                    style={{justifySelf:'center',display:'block'}}
                    color="primary"
                    value={typeLiv}
                    exclusive
                    onChange={handleTypeChange}
                    aria-label="Search Option"
                    disabled={commande.length>0}

                >
                    <ToggleButton value={false}>Vente</ToggleButton>
                    <ToggleButton value={true}>Retour</ToggleButton>
                </ToggleButtonGroup>
                <Button variant="contained" onClick={(typeLiv)?handleOpenRetour:handleOpen} startIcon={<Iconify icon="eva:plus-fill" />}>
                    Ajouter Un Produit
                </Button>
            </Stack>
            <Scrollbar>
            <Stack direction="column" alignItems="center" justifyContent="space-evenly" alignContent='center' m={3} spacing={3}>
                    <Typography variant="h6" noWrap alignContent='center' textOverflow='revert'>
                        Table des Produits pour cette Livraison : 
                    </Typography>
                    <Typography variant="h6" noWrap alignContent='center' textOverflow='revert' sx={{color:typeLiv?"error.main":"success.main"}}>
                        Total : {(typeLiv && livTotal>0)?`- ${livTotal}`:`${livTotal}`} DHs
                    </Typography>
                    <TextField sx={{width:'50%',minWidth:250}} inputProps={{type:"number"}} name="bon" value={num_bon} label="Numero de Bon" onChange={(e)=>setNum_bon(e.target.value)}/>
                    <TableContainer sx={{ minWidth: 800 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell align = 'center'>
                                    Réference
                                </TableCell>
                                <TableCell align = 'center'>
                                    Quantite
                                </TableCell>
                                <TableCell align = 'center'>
                                    Prix
                                </TableCell>
                                <TableCell align = 'center'>
                                    {' '}
                                </TableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                        {commande.map((row,index) => {
                            const {reference,price,toSell,id,stockOwner} = row;
                            
                            return (
                            <TableRow hover onClick={()=>{console.log("rowClicked",id)}} key={index} tabIndex={-1}  >
                               <TableCell component="th" align="center" scope="row" padding="none">
                                    <Typography variant="subtitle2" noWrap>
                                        {reference}{` (${stockOwner})`}
                                    </Typography>
                                </TableCell>
                                
                                
                                <TableCell align="center">{toSell}</TableCell>
                                <TableCell align="center">{price}</TableCell>
                                <TableCell align="center">
                                    <IconButton size="large" sx={{ color: 'error.main' }} onClick={()=>{
                                        setLivTotal(livTotal - toSell*price)
                                        let cpComm = [...commande]
                                        let cpCommIds = [...golbalIDs]
                                        cpCommIds.splice(index,1)
                                        setGlobalIDs(cpCommIds)
                                        cpComm.splice(index,1)
                                        setCommande(cpComm)
                                        
                                        }}>
                                        <Iconify icon={'eva:trash-2-outline'} />
                                    </IconButton>
                                </TableCell>
                                
                            </TableRow>
                            );
                        })}
                        
                        </TableBody>

                        
                    </Table>
                    </TableContainer>


                    <Button variant="contained" disabled={commande.length === 0 || num_bon.length === 0} onClick={validerLivraison} startIcon={<Iconify icon="eva:checkmark-circle-outline" />}>
                        Valider Livraison
                    </Button>
                </Stack>
            </Scrollbar>

        </Container>
        </>
    );







}